'use strict'

import { CmsConfig } from 'cms/config'
import { Logtail } from '@logtail/browser'

/**
 *
 *   Logging is from BetterStack. It requires a source token to run. A free account is possible.
 *     https://betterstack.com
 *     - a token is required to run. with it you can see logs in a web console
 *     - you can then enable console logs using 'REACT_APP_ENABLE_CONSOLE_LOG=true'
 *     - see Config for more env vars
 */

let Logger
const ConsoleLogger = console
const source_token = CmsConfig.Logging.SourceToken
Logger = new Logtail(source_token)
const alertColour = 'color: orange; background-color: black;'
const LOCAL_LOGGING_ONLY = process.env.REACT_APP_LOG_SOURCE_TOKEN === undefined
if (LOCAL_LOGGING_ONLY) {
    
    console.log('%c Cloud Service Logging is not available due to configuration. Source Token error is expected when no source token is added to env vars. ', alertColour)
    console.log('%c Logging is from BetterStack.  A free account is possible.  https://betterstack.com ', alertColour)
    
    // set up the Logger as console
    Logger = ConsoleLogger
    ConsoleLogger.flush = async () => {}

} else {
    
    if (CmsConfig.Logging.enableConsole) {
        // this means you want just the console
        Logger = ConsoleLogger
        ConsoleLogger.flush = async () => {}
        console.log('%c Cloud Service Logging was disabled in favour of local only console logging. ', alertColour)
        
    } else {
        // this means you want Cloud Logs
        const developerLog = (log) => {
            
            if (log.level === 'error') {
                const error = log.error
                const { message, stack } = error || {}
                // const error_message = `[${log.dt.toISOString()}] [${log.level}] ${log.message} ${message} \n${stack}`
                const error_message = `[${log.dt.toISOString()}] 🌆 [${log.level.toUpperCase()}] ${log.message} ${message}`
                // ConsoleLogger.error(error_message, log.error, log.context)
                // ConsoleLogger.error(log.message, log.error)
            }
            else if (log.level === 'warn') {
                // ConsoleLogger.warn(log.message, log.context)
            }
            else {
                const message = `[${log.dt.toISOString()}]  🌆 [${log.level.toUpperCase()}] ${log.message}`
                log.message = message
            }
            return log
        }
        Logger.use(developerLog)
        
    }
}
export { Logger, ConsoleLogger }

