import React from 'react'
import { Button, IconButton } from "@mui/material"
import { CmsConfig } from 'cms/config'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc.js'
dayjs.extend(utc)
import { getFields, getValue } from 'cms/utils/values-settings'
import { RichTextSimple } from 'cms/rich-text/rich-text-simple'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const PreviewAlertBanner = (props) => {
    
    const {
        alert,
        enabled,
        // alertLoaded,
        showBanner,
        handleCloseBanner,
        onClickBanner,
    } = props
  
    if (showBanner && enabled) {
        
        const { model, webpage } = alert || {}
        const { title, updated_at } = model || {}
        const { path } = webpage || {}
        const fields = getFields(model)
        const { summary } = fields
        // Get the value of the Summary directly avoiding useSimplicity (not a regular webpage/model).
        const summaryValue = getValue(summary)
        const date = dayjs.utc(updated_at).local().format(CmsConfig.dates.display.date_fullday_with_time)
        
        return (
            <div className="alert-banner">
                <span className="alert-banner-icon">
                    <FontAwesomeIcon icon={`fa-regular fa-circle-exclamation`} />
                </span>
                <div className="alert-banner-container">
                    
                    <div className="alert-banner-content">
                        <h3>
                            <span className="alert-banner-title">{title}</span>
                            <span style={{ padding: '0 4px' }}>-</span>
                            <span className="alert-banner-time-stamp">
                                {date}
                            </span>
                        </h3>
                        
                        <RichTextSimple richtext={summaryValue} />
                        
                    </div>
                    
                    <div className="alert-banner-actions">
                        <Button
                            className="LearnMore"
                            onClick={(event) => onClickBanner(event, path)}
                        >
                            Learn More
                        </Button>
                        
                        <IconButton
                            aria-label="close"
                            onClick={handleCloseBanner}
                        >
                            <FontAwesomeIcon icon={"fax"} />
                        </IconButton>
                    </div>
                </div>
            
            </div>
        )
    }
}   
